<template>
    <Guest> 
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-white">
                WACHTWOORD RESETTEN
            </h2>
        </div>
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <p class="pb-6">Wachtwoord vergeten? Dan kan je hier een mailtje laten versturen om hem te resetten.</p>
                <TextInput label="E-mailadres" placeholder="E-mailadres" border="enable" />
            </div>
        
            <div class="mt-6 grid grid-cols-2 gap-3">   
                <router-link :to="{ name: 'login' }">
                    <button type="button" class="button-transparent w-full">
                        Ga terug
                    </button>
                </router-link>
                        
                <button type="button" class="button w-full">
                    Versturen
                </button>
            </div> 
        </div>
    </Guest>
</template>

<script>
import Guest from '@/views/wrappers/Guest';
import TextInput from "@/components/forms/TextInput";

export default {
    name: "Forgot",
    components: { 
        Guest, 
        TextInput,
    },
}
</script>